.job-details-page {
  overflow: hidden;
}

.details-content-section {
  padding: 2%;
}

.visible-section {
  background-color: #ffffff44;
  border-radius: 0.4rem;
  color: black;
}

.detail-field {
  font-size: 1.2rem;
  border-radius: 15px;
}

.btn-conteiner {
  display: flex;
  justify-content: center;
  --color-text: #9ee5fa;
  --color-background: #353535;
  --color-outline: #9ee5fa80;
  --color-shadow: #00000080;
  cursor: pointer;
}

.btn-content {
  display: flex;
  align-items: center;
  padding: 0px 3px;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 5px;
  color: var(--color-text);
  background: var(--color-background);
  transition: 1s;
  border-radius: 100px;
  box-shadow: 0 0 0.2em 0 var(--color-background);
}

.btn-content:hover,
.btn-content:focus {
  transition: 0.5s;
  -webkit-animation: btn-content 1s;
  animation: btn-content 1s;
  outline: 0.1em solid transparent;
  outline-offset: 0.2em;
  box-shadow: 0 0 0.4em 0 var(--color-background);
}

.btn-content .icon-arrow {
  transition: 0.5s;
  margin-right: 0px;
  transform: scale(0.6);
}

.btn-content:hover .icon-arrow {
  transition: 0.5s;
  margin-right: 25px;
}

.icon-arrow {
  width: 20px;
  margin-left: 15px;
  position: relative;
  top: 6%;
}

#arrow-icon-one {
  transition: 0.4s;
  transform: translateX(-60%);
}

#arrow-icon-two {
  transition: 0.5s;
  transform: translateX(-30%);
}

.btn-content:hover #arrow-icon-three {
  animation: color_anim 1s infinite 0.2s;
}

.btn-content:hover #arrow-icon-one {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.6s;
}

.btn-content:hover #arrow-icon-two {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.4s;
}

@keyframes color_anim {
  0% {
    fill: white;
  }

  50% {
    fill: var(--color-background);
  }

  100% {
    fill: #9ee5fa;
  }
}

@-webkit-keyframes btn-content {
  0% {
    outline: 0.2em solid var(--color-background);
    outline-offset: 0;
  }
}

@keyframes btn-content {
  0% {
    outline: 0.2em solid var(--color-background);
    outline-offset: 0;
  }
}

.hidden-section {
  margin-top: 2%;
  background-color: #ffffff44;
  border-radius: 0.4rem;
  /* padding: 1%; */
}

.hidden-detail-field {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s ease-in-out;
}

.show-details .hidden-detail-field {
  max-height: 40rem;
}

.delete-button-4 {
  margin-top: 2%;
  margin-right: 2%;
  appearance: none;
  background-color: #fafbfc;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0,
    rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #24292e;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 6px 16px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
}

.delete-button-4:hover {
  background-color: #f3f4f6;
  text-decoration: none;
  transition-duration: 0.1s;
}

.delete-button-4:disabled {
  background-color: #fafbfc;
  border-color: rgba(27, 31, 35, 0.15);
  color: #959da5;
  cursor: default;
}

.delete-button-4:active {
  background-color: #edeff2;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
  transition: none 0s;
}

.delete-button-4:focus {
  outline: 1px transparent;
}

.delete-button-4:before {
  display: none;
}

.delete-button-4:-webkit-details-marker {
  display: none;
}

.applied-applicants-div {
  height: 100%;
}

.card-cv-div {
  padding: 1rem;
  border-radius: 1rem;
  background-color: #d3f1ff;
  border: 2px solid #22b4ed;
}

.cv-card {
  /* border-radius: 10px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title {
  /* font-size: 1.25rem; */
  margin-bottom: 10px;
}

.card-text {
  font-size: 1rem;
  color: #555;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .card-text {
    font-size: 1rem;
    color: #555;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2%;
  }
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}

.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.details-container {
  border: 2px solid #22b4ed !important;
  /* background-color: aliceblue !important; */
}

.remove-applicant-button {
  border: none !important;
}

.remove-applicant-button svg {
  font-size: 1.8rem;
}
