@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.button-4 {
  appearance: none;
  background-color: #fafbfc;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0,
    rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #24292e;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 6px 16px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
}

.button-4:hover {
  background-color: #f3f4f6;
  text-decoration: none;
  transition-duration: 0.1s;
}

.button-4:disabled {
  background-color: #fafbfc;
  border-color: rgba(27, 31, 35, 0.15);
  color: #959da5;
  cursor: default;
}

.button-4:active {
  background-color: #edeff2;
  box-shadow: rgba(225, 228, 232, 0.2) 0 1px 0 inset;
  transition: none 0s;
}

.button-4:focus {
  outline: 1px transparent;
}

.button-4:before {
  display: none;
}

.button-4:-webkit-details-marker {
  display: none;
}

.link-navbar-item:hover {
  border-radius: 0.5rem;
  background: rgb(255, 183, 121);
  background: linear-gradient(
    321deg,
    rgba(255, 183, 121, 1) 3%,
    rgba(255, 178, 107, 1) 36%,
    rgba(254, 160, 76, 1) 77%
  );
}

.navbar {
  background-color: #22b4ed !important;
  color: white !important;
}

.nav-link {
  color: white !important;
}

.navbar-dropdown-element {
  cursor: pointer;
}

.nav-bar-dropdown-menu {
  position: absolute;
  top: calc(100% + 5px);
  /* left: -180px; */
  left: calc(100% - 250);
  padding: 1rem;
  color: black;
}

@media screen and (max-width: 700px) {
  .nav-bar-dropdown-menu {
    left: calc(100% - 230px);
  }
}

.navbar-profile-icon {
  position: relative;
}

.navbar-profile-icon svg {
  font-size: 2rem;
  cursor: pointer;
}

.profile-details {
  border-bottom: 1px solid gray;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  user-select: none;
  width: 100%;
}

.profile-details .name {
  font-weight: 600;
  color: #484848;
  color: #22b4ed;
  /* font-family: "Inter var", sans-serif; */
  font-family: "Inter", sans-serif;
}

.profile-details .email {
  font-weight: 400;
  color: #484848;
  /* font-family: "Inter var", sans-serif; */
  font-family: "Inter", sans-serif;
  width: 100%;
}

.profile-account {
  border-bottom: 1px solid gray;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  user-select: none;
}

.profile-account-link {
  text-decoration: none;
  color: #484848;
}

.dropdown-toggle {
  width: 100%;
}

.dropdown .dropdown-item:hover {
  background-color: #22b4ed;
  color: #fff;
}

.blur-background {
  filter: blur(0.5rem);
}

.applicant-profile-image {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 100%;
  cursor: pointer;
  border: 2px solid #fff;
}
