@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.footer {
  color: #868686;
  background-color: #323232;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px 8vw;
  padding-top: 30px;
  margin-top: 1rem;
  font-family: "Inter", sans-serif;
}
.Address {
  max-width: 800px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center; /* This will center items horizontally */
  margin: 0 auto; /* This will center the `.Address` container itself */
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.footer-logo p {
  color: #22b4ed;
  font-size: 30px;
  margin-bottom: 10px;
  font-family: "Arial", "Helvetica", sans-serif;
  font-weight: 550;
  font-family: "Inter", sans-serif;
}

.footer-social-icons {
  display: flex;
  text-align: center;
  gap: 10px;
  margin-top: 0;
}

.footer-social-icons img {
  width: 30px;
  transition: transform 0.3s ease-in-out;
}

.footer-social-icons img:hover {
  transform: scale(1.2);
}

.footer-links ul {
  display: flex;
  gap: 20px;
  padding: 0;
}

.footer-links li {
  list-style: none;
  cursor: pointer;
  font-size: 15px;
  margin: 20px 25px;
  position: relative;
}

.footer-links li a {
  text-decoration: none;
  color: #b8b8b8;
  position: relative;
  padding-bottom: 2px;
}

.footer-links li a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 0;
  background-color: #22b4ed;
  transition: width 0.4s ease-in-out;
}

.footer-links li a:hover::after {
  width: 100%;
}

.footer-contact {
  display: flex;
  justify-content: center;
  gap: 70px;
  text-align: center;
  width: 100%;
}

.footer-contact ul {
  display: flex;
  gap: 20px;
  padding: 0;
  list-style: none;
}

.footer-contact li {
  display: flex;
  align-items: center;
  gap: 10px;
  color: rgb(155, 155, 155);
}

.footer-contact li img {
  width: 25px;
  /* margin: 0px 10px 0px 100px; */
}

.footer hr {
  width: 100%;
  height: 2px;
  margin: 0;
  background-color: grey;
  border: none;
}

.footer-copywrite {
  text-align: center;
  margin: 0.5rem 0 0 0;
}

@media (max-width: 1200px) {
  .footer {
    padding: 20px 5vw;
    gap: 25px;
  }
  .footer-content {
    gap: 25px;
  }
  .footer-contact {
    gap: 50px;
  }
}

@media (max-width: 992px) {
  .footer {
    padding: 20px 4vw;
    gap: 20px;
  }
  .footer-content {
    gap: 20px;
  }
  .footer-contact {
    gap: 40px;
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 20px 3vw;
    gap: 15px;
  }
  .footer-content {
    gap: 15px;
  }
  .footer-contact {
    gap: 30px;
    flex-direction: column;
    align-items: center;
  }
  .footer-contact ul {
    flex-direction: column;
    gap: 10px;
  }
  .footer-links ul {
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
  .footer-links li {
    margin: 10px 0;
  }
}

@media (max-width: 576px) {
  .footer {
    padding: 20px 2vw;
    gap: 10px;
  }
  .footer-content {
    gap: 10px;
  }
  .footer-logo p {
    font-size: 25px;
  }
  .footer-contact li img {
    width: 20px;
    margin: 0;
  }
  .footer hr {
    height: 1px;
  }
  .footer-copywrite {
    font-size: 14px;
  }
}
