/* Backdrop overlay for blur effect */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 1001;
}

/* Main container for the applicant details */
.details-applicant-details-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  padding: 1rem;
  max-width: 100%;
}

/* Profile Picture and Name Section */
.details-profile-header {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  background-color: #22b4ed;
  border-radius: 0.5rem;
  border-top-left-radius: 5rem;
  border-bottom-left-radius: 5rem;
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
}

/* Profile image styling */
.details-company-side-applicant-profile-image {
  width: 9rem;
  height: 9rem;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #22b4ed;
  transition: all 0.3s ease-in-out;
}

.details-company-side-applicant-profile-image:hover {
  transform: scale(1.2);
}

/* Default profile icon styling */
.details-default-profile-icon {
  width: 120px;
  height: 120px;
  color: #fff;
  border-radius: 50%;
  border: 3px solid #22b4ed;
}

/* Name section styling */
.details-name-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.3rem;
  /* color: white; */
}

.details-name-section p {
  margin: 0;
  /* font-size: 1.1rem; */
  /* font-weight: bold; */
  color: rgb(49, 48, 48);
}

/* Additional details side by side */
.details-other-details {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  width: 100%;
}

.details-detail-column {
  flex: 1;
  min-width: 250px;
}

/* Recent Sea Service Section */
.details-sea-service-details {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  width: 100%;
}

.details-detail-column {
  flex: 1;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .details-profile-header {
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-top-left-radius: 0rem;
    border-bottom-left-radius: 0rem;
  }

  .details-company-side-applicant-profile-image,
  .details-default-profile-icon {
    width: 80px;
    height: 80px;
    color: #22b4ed;
  }

  .details-name-section p {
    font-size: 1rem;
  }

  .details-sea-service-details {
    flex-direction: column;
  }
}
