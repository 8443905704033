.values-container {
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 40px 20px;
  box-sizing: border-box;
}

.values-container h1 {
  font-size: 50px;
  margin-bottom: 30px;
  color: #000;
}

.values-icons {
  display: flex;
  justify-content: center;
  gap: 2%;
  margin-bottom: 40px;
  flex-wrap: wrap;
}

.value-item {
  text-align: center;
  width: 22%;
  min-width: 160px;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 25px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  box-sizing: border-box;
}

.value-item:hover {
  transform: translateY(-5px);
}

.value-item img {
  width: 100%;
  max-width: 130px;
  height: auto;
  margin-bottom: 20px;
  object-fit: contain;
}

.value-item p {
  font-size: 22px;
  color: #333;
  margin: 0;
}

.join-us {
  background-color: #ffffff;
  padding: 30px 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.join-us h2 {
  font-size: 40px;
  margin-bottom: 15px;
  color: #000;
}

.join-us p {
  font-size: 19px;
  color: #333;
}

@media (max-width: 1024px) {
  .values-container h1 {
    font-size: 40px;
  }

  .values-icons {
    gap: 3%;
  }

  .value-item {
    width: 45%;
    min-width: 140px;
    padding: 20px;
  }

  .value-item img {
    max-width: 110px;
  }

  .value-item p {
    font-size: 20px;
  }

  .join-us h2 {
    font-size: 36px;
  }

  .join-us p {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .values-icons {
    flex-direction: column;
    gap: 20px;
  }

  .value-item {
    width: 80%;
    margin: 0 auto;
  }

  .value-item img {
    max-width: 100px;
  }

  .value-item p {
    font-size: 18px;
  }

  .join-us h2 {
    font-size: 30px;
  }

  .join-us p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .values-container {
    padding: 20px 10px;
  }

  .values-container h1 {
    font-size: 32px;
    margin-bottom: 20px;
  }

  .value-item {
    width: 100%;
  }

  .value-item img {
    max-width: 80px;
  }

  .value-item p {
    font-size: 16px;
  }

  .join-us {
    padding: 20px 10px;
  }

  .join-us h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .join-us p {
    font-size: 14px;
  }
}
