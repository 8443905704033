.crop-container {
  position: relative;
  width: 100%;
  height: 20rem;
  background: #fff;
}

.crop-area {
  border-radius: 50%;
  overflow: hidden;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.controls button {
  margin: 5px;
}
