.card-text-details {
  display: flex;
  justify-content: space-between;
  color: #ff4e4e;
}

.applied-card-body {
  background-color: #fff;
  padding: 3%;
  border-radius: 1rem;
  cursor: pointer;
  border: 2px solid #22b4ed;
}
