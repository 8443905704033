/* .job-carousel-container {
  overflow: hidden;
  width: 100%;
}
.job-carousel {
  display: flex;
  flex-direction: column;
  animation: scroll 20s linear infinite;
  max-height: 35rem;
}

.ad-job-card {
  flex: 0 0 auto;
  width: 100%;
  height: 4rem;
  margin-bottom: 1rem;
  background-color: #f0f0f0;
  border-radius: 10px;
  font-weight: bold;
  font-size: 1.2rem;
  cursor: pointer;
  padding-left: 2rem;
  padding-right: 2rem;
  align-content: center;
}

.ad-content {
  color: rgb(28, 45, 78);
}

@keyframes scroll {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}

.add-card-info {
  display: flex;
  text-align: center;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .ad-job-card {
    flex: 0 0 auto;
    width: 100%;
    height: 4rem;
    margin-bottom: 1rem;
    background-color: #f0f0f0;
    border-radius: 10px;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    padding-left: 1rem;
    padding-right: 1rem;
    align-content: center;
  }
} */

.job-carousel-container {
  overflow: hidden;
  width: 100%;
}
.job-carousel {
  display: flex;
  flex-direction: column;
  animation: scroll 28s linear infinite;
  max-height: 35rem;
  white-space: nowrap;
}

.ad-job-card {
  flex: 0 0 auto;
  width: 100%;
  height: 4rem;
  margin-bottom: 1rem;
  background-color: #f0f0f0;
  border-radius: 10px;
  font-weight: bold;
  font-size: 1.2rem;
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 2rem;
  align-content: center;
  display: inline-block;
}

.ad-content {
  /* color: rgb(28, 45, 78); */
  color: rgb(48, 48, 48);
}

@keyframes scroll {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

.add-card-info {
  display: flex;
  text-align: center;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 768px) {
  .ad-job-card {
    flex: 0 0 auto;
    width: 100%;
    height: 4rem;
    margin-bottom: 1rem;
    background-color: #f0f0f0;
    border-radius: 10px;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    padding-left: 1rem;
    padding-right: 1rem;
    align-content: center;
  }
}
.job-title {
  font-weight: normal; /* Set the job title font weight to normal */
  color: black;
}

.job-carousel:hover .ad-job-card {
  animation-play-state: paused;
}

.blur-background {
  overflow: hidden;
  filter: blur(0.8rem);
}

.ad-company-name-logo {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  align-items: flex-end;
}

.ad-company-name-logo svg {
  height: 2.5rem;
  width: 2.5rem;
  margin: 0 1.2rem 0rem 0;
  text-align: center;
  align-items: flex-end;
  border: 1px solid #22b4ed;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
}

.landing-page-job-carousel-profile-image {
  height: 2.5rem;
  width: 2.5rem;
  margin: 0 1.2rem 0rem 0;
  text-align: center;
  align-items: flex-end;
  border: 2px solid #22b4ed;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
}

.landing-page-job-carousel-profile-image:hover {
  transform: scale(1.3);
}

@media (max-width: 1300px) {
  .ad-company-name-logo h4 {
    font-size: 1.1rem;
  }

  .job-title {
    font-size: 1rem;
    text-wrap: wrap;
    text-align: right;
  }
}

@media (max-width: 768px) {
  .ad-company-name-logo h4 {
    font-size: 1.1rem;
  }

  .job-title {
    font-size: 0.8rem;
    text-wrap: pretty;
    text-align: right;
  }

  .ad-job-card {
    height: 100%;
    padding: 2%;
  }
}
