.Hero-Img {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  background-color: #f0f8ff;
}

.Hero-Img img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .Hero-Img {
    padding: 20px;
  }
}
