.applicant-page {
  overflow: hidden;
  overflow-x: hidden;
}

.post-form-section {
  height: 15rem;
  padding: 2rem;
}

.post-form-card {
  height: 100%;
  width: 100%;
  /* background-color: aliceblue; */
  background-color: #a7e6ff;
  border: 2px solid #22b4ed;
  border-radius: 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;
}

.buttons-section {
  display: flex;
  justify-content: space-around;
}

.buttons-section .btn {
  background-color: white;
  border-color: #22b4ed;
  outline: none;
}

.buttons-section .btn:hover {
  border-color: white;
  color: #22b4ed;
  background-color: white;
}

.applied-jobs-section {
  /* background-color: blue; */
  height: 20rem;
  padding: 2rem;
  overflow-y: auto;
}

.jobs-section {
  /* background-color: red; */
  height: 35rem;
  padding: 2rem;
}

.jobs-section-title {
  /* background-color: #FFD5A5; */
  background-color: #fff;
  padding: 2% 4%;
  border-radius: 2rem;
  display: flex;
  justify-content: space-between;
  border: 3px solid #7fdbff;
  user-select: none;
}

.jobs-section-title-text {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  color: #22b4ed;
}

.jobs-section-title-icon-applicant {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.jobs-section-title-icon-applicant svg {
  font-size: 1.2rem;
  cursor: pointer;
}

.jobs-section-elements {
  height: 100%;
  /* background-color: orange; */
  margin-top: 2rem;
  padding: 2%;
  border-top-left-radius: 2rem;
}

/* Add this to your Applicant.css file */
.applicant-modal-content {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.applicant-modal-body {
  padding: 20px;
}

.applied-jobs {
  margin-top: 20px;
}

.applied-jobs ul {
  padding-left: 0;
  list-style: none;
}

.applied-jobs li {
  margin-bottom: 5px;
}

/* Add this to your Applicant.css file */
.selected-jobs-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  overflow-y: auto;
}

.selected-job-card {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.selected-job-name {
  margin-right: 5px;
}

.remove-btn {
  border: none;
  background: transparent;
  color: #888;
  cursor: pointer;
}

.blur-background {
  filter: blur(0.5rem);
}

.jobs-section-elements {
  overflow-y: auto;
  padding: 2rem;
  padding-top: 1rem;
  padding-bottom: 10rem;
}

.dropdown-element {
  cursor: pointer;
}

.dropdown-element:hover {
  background-color: #dfdbdb;
  background-color: #22b4ed;
}

.dropdown-item.dropdown-element:hover {
  background-color: #22b4ed;
  color: #fff; /* Optional: Change text color to white on hover */
}

.jobs-section-title-icon-applicant {
  position: relative; /* Set the icon's position to relative */
}

.dropdown-menu {
  position: absolute;
  top: calc(100% + 5px); /* Position the dropdown just below the icon */
  left: -130px; /* Align the dropdown with the left edge of the icon */
}

.search-bar-div {
  /* background-color: yellow; */
  padding: 1rem 3rem 0 2rem;
}

.search {
  display: inline-block;
  position: relative;
  width: -webkit-fill-available;
}

.search input[type="text"] {
  width: -webkit-fill-available;
  padding: 10px;
  border: 2px solid #a7e6ff;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #22b4ed;
  outline: none;
}

.search button[type="submit"] {
  background-color: aliceblue;
  border: 2px solid #a7e6ff;
  color: #22b4ed;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  right: 0;
  transition: 0.5s ease;
  outline: none;
}

.search button[type="submit"]:hover {
  /* transform: scale(1.1); */
  color: rgb(255, 255, 255);
  background-color: #22b4ed;
  border: 2px solid #22b4ed;
}

.input-search-bar::selection {
  background-color: #22b4ed;
  color: #fff;
}

@media (max-width: 768px) {
  .buttons-section .btn {
    /* height: 2rem; */
    height: 100%;
    font-size: 0.85rem;
    padding: 0.22rem;
  }

  .jobs-section {
    /* background-color: red; */
    height: 35rem;
    padding: 1rem;
  }

  .jobs-section-elements {
    overflow-y: auto;
    padding: 0.4rem;
    padding-top: 1rem;
    padding-bottom: 10rem;
  }

  .search-bar-div {
    padding: 1rem 0.9rem 0 0.9rem;
  }
  .applied-jobs-section {
    /* background-color: blue; */
    height: 20rem;
    padding: 1.2rem;
    overflow-y: auto;
  }

  .post-form-section {
    height: fit-content;
    width: fit-content;
    padding: 1rem;
  }
}
