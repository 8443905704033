.modal-body {
  overflow: hidden;
}

.form-div {
  width: 100%;
  height: fit-content;
  background-color: #fff;
  border-radius: 1rem;
  user-select: none;
}

.form-div h3 {
  text-align: center;
}

.form-tag {
  margin: 3% 0;
}

.form-button-div {
  text-align: center;
}

.login-info {
  display: flex;
  /* padding-top: 4%; */
}

.login-link {
  text-decoration: none;
}

.form-check {
  text-align: center;
  justify-content: center;
  padding-top: 1%;
  padding-bottom: 1%;
}

.password-input-container {
  position: relative;
}

.eye-icon {
  position: absolute;
  right: 10px;
  top: 45%;
  transform: translateY(-50%);
  cursor: pointer;
}

.eye-icon svg {
  font-size: 1rem;
  color: #999;
}

.eye-icon:hover svg {
  color: #333;
}

.forgot-password-link {
  text-decoration: none;
}

.alert-div {
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
}
.form-tag .alert {
  padding: 1%;
  width: fit-content;
}
