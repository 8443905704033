.job-link-edit-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.job-link-edit-modal-content {
  background-color: #fefefe;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
  width: 80%;
  max-width: 32rem;
  height: 19rem;
  max-height: calc(100% - 40px);
  /* overflow-y: auto;  */
  display: flex;
  flex-direction: column;
}

.job-link-edit-modal-close {
  display: flex;
  cursor: pointer;
  font-size: 2rem;
  padding-bottom: 2%;
  flex-direction: row;
  justify-content: space-between;
}

.cross-button {
  display: flex;
  text-align: right;
}

.job-link-edit-modal form {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 2%;
}

.job-link-edit-modal button {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.job-link-edit-modal button:hover {
  background-color: #0056b3;
}

.link-edit-post-title {
  text-align: left;
}
