.job-card {
  box-sizing: border-box;
  width: 15rem;
  height: 12rem;
  background: rgb(153, 217, 240);
  background: linear-gradient(
    335deg,
    rgba(153, 217, 240, 1) 3%,
    rgba(154, 226, 255, 1) 36%,
    rgba(79, 206, 255, 1) 77%
  );
  border: 1px solid #22b4ed;
  box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
  backdrop-filter: blur(6px);
  border-radius: 17px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  color: black;
  padding: 2%;
}

.job-card:hover {
  border: 1px solid black;
  transform: scale(1.05);
}

.job-card-content p {
  margin-top: 3rem;
}
