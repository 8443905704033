.upload-pdf-section {
  text-align: center;
}

.pdf-input {
  /* margin: 5%; */
  width: 100%;
  margin-bottom: 2rem;
}

.upload-input-div {
  /* border: 2px dashed #f99004; */
  border: 2px dashed #22b4ed;
  border-radius: 1rem;
  padding: 4rem;
  margin: 20px auto;
  width: 40rem;
}

@media (max-width: 1000px) {
  .upload-input-div {
    /* border: 2px dashed #f99004; */
    border: 2px dashed #22b4ed;
    border-radius: 1rem;
    padding: 4rem;
    margin: 20px auto;
    width: 100%;
  }
}
