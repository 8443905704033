/* .pdf-details-section {
  padding: 2%;
}

.visible-pdf-section {
  background-color: #ffffff44;
  border-radius: 0.4rem;
}

.detail-field {
  font-size: 1.2rem;
}

.details-container {
  border: 2px solid blue;
}

.download-button-div {
  display: flex;
}

.download-button {
  cursor: pointer;
  margin-left: 2%;
}

.details-container {
  border: 2px solid #22b4ed !important;
} */

.pdf-details-section {
  padding: 2%;
}

.visible-pdf-section {
  background-color: #ffffff44;
  border-radius: 0.4rem;
}

.detail-field {
  font-size: 1.2rem;
}

.details-container {
  border: 2px solid blue;
}

.download-button-div {
  display: flex;
}

.download-button {
  cursor: pointer;
  margin-left: 2%;
}

.details-container {
  border: 2px solid #22b4ed !important;
}
.similar-jobs-card {
  height: 100%;
  /* background-color: orange; */
  margin-top: -1rem;
  padding: 2%;
  border-top-left-radius: 2rem;
}
.similar-jobs-card h3 {
  color: #22b4ed;
}
.similar-jobs-list {
  margin-top: 30px;
}
