/* App.css */

/* .App {
  text-align: center;
  padding: 20px;
}

table {
  margin-top: 30px;
} */
/* .App {
    font-family: Arial, sans-serif;
    padding: 20px;
  }
   */
/* .card {
    background-color: #fff; 
    border: 2px solid #87CEEB; 
    border-radius: 8px;
    margin-bottom: 20px;
  } */
/*   
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #f0f0f0;
    border-bottom: 1px solid #ddd;
  }
  
  .card-title {
    font-weight: bold;
    font-size: 18px;
  }
  
  .dropdown-icon {
    font-size: 20px;
    transition: transform 0.3s ease;
  }
  
  .dropdown-icon.open {
    transform: rotate(180deg);
  }
  
  .card-body {
    padding: 20px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th,
  .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .table th {
    background-color: #f0f0f0;
    font-weight: bold;
  }
   */

:root {
  --button-color: #4e99e9;
}

/* Style for the card container */
.card {
  border: 1px solid var(--button-color); /* Use the custom button color for border */
  border-radius: 8px;
  margin: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Style for the card header */
.card-header {
  background-color: #f0f0f0;
  padding: 12px 5px 12px 10px !important;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-header h2 {
  margin: 0;
  font-size: 18px;
  color: var(--button-color); /* Use the custom button color for header text */
}

.dropdown-icon {
  border: none;
  background-color: transparent;
  font-size: 24px;
  color: #333;
  cursor: pointer;
  outline: none;
}

.card-content {
  padding: 20px;
}

/* Style for the table */
.table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid var(--button-color); /* Use the custom button color for table border */
}

.table th,
.table td {
  border: 1px solid var(--button-color); /* Use the custom button color for cell borders */
  padding: 10px;
  text-align: left;
}

.table th {
  background-color: var(
    --button-color
  ); /* Use the custom button color for column header background */
  color: #fff; /* Text color for column header */
}

.table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table tbody tr:hover {
  background-color: #f1f1f1;
}

/* Style for the link inside table */
.table a {
  text-decoration: none;
  color: var(--button-color); /* Use the custom button color for link text */
}

.table a:hover {
  text-decoration: underline;
}

.other-page-search {
  display: inline-block;
  position: relative;
  margin-left: auto;
}

.other-page-search input[type="text"] {
  width: 300px;
  padding: 10px;
  border: none;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.other-page-search button[type="submit"] {
  background-color: var(--button-color); /* Use the custom button color */
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  right: 0;
  transition: 0.9s ease;
}

.other-page-search button[type="submit"]:hover {
  transform: scale(1.1);
  color: #fff;
  background-color: blue; /* Adjust the hover color as needed */
}

.suggestions {
  list-style-type: none;
  padding: 0;
  margin: 5px 0 0;
  width: 300px;
  position: absolute;
  top: calc(100% + 5px); /* Position below the input */
  left: 0;
  z-index: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
}

.suggestions li {
  padding: 12px;
  cursor: pointer;
  font-size: 16px;
}

.suggestions li:hover,
.suggestions li:focus {
  background-color: #f5f5f5;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.close-icon-container {
  text-align: end;
  margin-top: 10px;
}
.dropdown-close-icon {
  background-color: #4e99e9;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  transition: 0.9s ease;
}
.dropdown-close-icon:hover {
  transform: scale(1.1);
  color: #fff;
  background-color: blue;
}
.close-icon-container-last {
  text-align: end;
  margin-top: 10px;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .other-page-search input[type="text"] {
    width: 185px;
    padding: 10px;
    border: none;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .card-content {
    overflow-x: scroll;
  }
}
