.resume-builder-container {
  display: flex;
  flex-direction: row;
  height: auto;
}

.input-section {
  flex: 1;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 12px;
  margin: 20px;
  border: 2px solid #d4bffd;
  height: 100%;
}

.input-section h2 {
  font-size: 24px;
  font-weight: bold;
  color: #7c3aed;
  margin-bottom: 5px;
}

.input-section p {
  font-size: 16px;
  color: #6b7280;
  margin-bottom: 20px;
}

.input-section form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.userName,
.userContactInfo {
  display: flex;
  justify-content: space-between;
  gap: 10px; /* Adjust gap between input fields */
}

.userName div,
.userContactInfo div {
  flex: 1;
}

.input-section form label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.input-section form input {
  padding: 10px;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #e8f0fe;
}

.input-section form input[type="text"],
.input-section form input[type="tel"],
.input-section form input[type="email"] {
  width: 100%;
}

.input-section form input:focus {
  outline: none;
  border: 2px solid #7c3aed;
  background-color: #e0e7ff;
}

.pdf-preview-section {
  flex: 1;
  flex-direction: column;
  padding: 30px;
  margin: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border: 2px solid #ef4444;
  border-radius: 10px;
  background-color: #fff;
  width: 100%;
  height: fit-content;
  font-family: "Arial, sans-serif";
  color: #333;
}

.header {
  margin-bottom: 10px;
  text-align: left;
}

.header .name {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
}

.header .resume-job-title {
  font-size: 18px;
  font-weight: normal;
  color: black;
  margin-bottom: 5px;
}

.contact-info {
  font-size: 14px;
  margin: 2px 0;
}

.section-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  border-bottom: 1px solid black;
  padding-bottom: 5px;
  color: black;
}

.experience-item,
.education-item {
  margin-bottom: 10px;
}

.resume-job-title,
.university-name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 3px;
}

.company-info,
.degree-info,
.date-range {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
}
/* .company-info{
    margin-bottom: 5px;
  } */

.job-description,
.education-description {
  font-size: 14px;
  color: #444;
}

.skills-list {
  display: flex;
  flex-wrap: wrap;
}

.skill-item {
  margin-right: 15px;
  margin-bottom: 10px;
}

.skill-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 4px;
}

.skill-bar-filled {
  background-color: #ef4444;
  height: 6px;
}

/* Adjust your existing CSS styles */
.button-group {
  display: flex;
  justify-content: space-between;
}

.save-button,
.next-button,
.resume-builder-download-button {
  padding: 10px;
  background-color: #7c3aed;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 10px;
}

.prev-button {
  padding: 10px;
  background-color: #7c3aed;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 10px;
}
.save-button:hover,
.next-button:hover,
.prev-button:hover,
.resume-builder-download-button:hover {
  opacity: 0.8;
}

/* Container to hold label and button in the same line */
.summary-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.summary-header label {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.summary-container {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px; /* Add margin-bottom to create space for the suggestions */
  width: 100%; /* Ensure full width for container */
}

.summary-container textarea {
  flex: 1;

  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #e8f0fe;

  height: 10rem;
  width: 100%;
  box-sizing: border-box; /* Include padding and border in element's width and height */
}

.summary-container textarea:focus {
  outline: none;
  border: 2px solid #7c3aed;
  background-color: #e0e7ff;
}

.ai-button {
  padding: 10px 15px;
  background-color: lightgrey;
  color: black;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.ai-button:hover {
  background-color: grey;
  opacity: 0.9;
}

/* Style the suggestions container */
.suggestions-container {
  margin-top: 20px; /* Separate suggestions from the textarea */
  width: 100%; /* Ensure full width for suggestions container */
}

/* Experience section styling */
.experience-section {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
  background-color: #fdfdfd;
}

.experience-section > div {
  margin-bottom: 10px;
}

.experience-section label {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.experience-section input,
.experience-section textarea {
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #e8f0fe;
}

.experience-section input:focus,
.experience-section textarea:focus {
  outline: none;
  border: 2px solid #7c3aed;
  background-color: #e0e7ff;
}

.experience-section textarea {
  resize: none; /* Prevent resizing */
}

.experience-section button {
  background-color: #ff4d4f;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.experience-section button:hover {
  background-color: #ff7875;
}

.experienceGroupOne {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.experienceGroupOne div {
  flex: 1;
}

.add-experience-button {
  padding: 10px;
  background-color: #7c3aed;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  width: 100%; /* Make the button full-width */
}

.add-experience-button:hover {
  opacity: 0.8;
}
/* educational section styling */
.education-section {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
  background-color: #fdfdfd;
}

.education-section > div {
  margin-bottom: 10px;
}

.education-section label {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.education-section input,
.education-section textarea {
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #e8f0fe;
}

.education-section input:focus,
.education-section textarea:focus {
  outline: none;
  border: 2px solid #7c3aed;
  background-color: #e0e7ff;
}

.education-section textarea {
  resize: none; /* Prevent resizing */
}

.education-section button {
  background-color: #ff4d4f;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.education-section button:hover {
  background-color: #ff7875;
}

.educationGroupOne {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.educationGroupOne div {
  flex: 1;
}

.add-education-button {
  padding: 10px;
  background-color: #7c3aed;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  width: 100%; /* Make the button full-width */
}

.add-education-button:hover {
  opacity: 0.8;
}
.skill-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.skill-section input {
  width: 50%; /* Reduced width to make space for stars */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
  color: #333;
  margin-right: 20px;
}

.rating {
  display: flex;
  margin-right: 20px;
}

.rating input[type="radio"] {
  display: none;
}

.rating .star {
  font-size: 24px;
  cursor: pointer;
  color: #ccc;
  transition: color 0.2s;
  margin-right: 5px;
}

.rating .star.filled {
  color: orange;
}
.add-skill-button,
.remove-button {
  background-color: #6c5ce7;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}
.add-skill-button:hover,
.save-button:hover,
.remove-button:hover {
  background-color: #5a4dcf;
}

.remove-button {
  background-color: #dc3545;
  margin-top: 10px;
}

.remove-button:hover {
  background-color: #c82333;
}

/* pdf preview section */
.experience-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  margin-bottom: 10px;
}

.experience-table th,
.experience-table td {
  border: 1px solid #000;
  padding: 8px;
  text-align: left;
}

.experience-table th {
  background-color: #e0f7fa;
  color: #000;
}

.button-group-step {
  width: auto;
  display: flex;
  gap: 10px; /* Space between buttons */
}
.preview-resume-button {
  display: none;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .resume-builder-container {
    flex-direction: column;
  }

  .preview-resume-button {
    display: block;
    width: auto;
    padding: 10px;
    background-color: #7c3aed;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin: 20px;
  }

  .input-section {
    margin-top: 20px;
  }

  /* Hide PDF preview section by default on mobile */
  .pdf-preview-section {
    display: none;
  }

  /* Show PDF preview section as a modal */
  .pdf-preview-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .pdf-preview-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-height: 90%;
    overflow-y: auto;
    width: 95%;
  }
  .experience {
    overflow-x: scroll;
  }
  .close-modal-button {
    margin-top: 20px;
    padding: 10px;
    background-color: #7c3aed;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: block;
    width: auto;
  }
  .pdf-preview-modal .header .resume-job-title {
    font-size: 16px;
    text-align: left;
  }

  .pdf-preview-modal .header .name {
    font-size: 20px;
  }

  .pdf-preview-modal .section-title {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .pdf-preview-modal .experience-item,
  .pdf-preview-modal .education-item {
    margin-bottom: 8px;
  }

  .pdf-preview-modal .resume-job-title,
  .pdf-preview-modal .university-name {
    font-size: 14px;
  }

  .pdf-preview-modal .company-info,
  .pdf-preview-modal .degree-info,
  .pdf-preview-modal .date-range {
    font-size: 12px;
  }

  .pdf-preview-modal .skills-list {
    flex-direction: column;
  }

  .pdf-preview-modal .skill-item {
    margin-right: 0;
    margin-bottom: 8px;
  }

  .experience-table th,
  .experience-table td {
    font-size: 12px;
    padding: 6px;
  }
}

/* Larger screens keep the layout as is */
@media screen and (min-width: 769px) {
  .pdf-preview-section {
    display: flex;
  }
}
