/* .landing-page {
  overflow: hidden;
}

.landing-page .row {
  padding: 0;
}

.landing-section-one {
  height: 20rem;
  padding: 2rem;
  background-color: #d3d3d3;
}

.advertisement-card {
  height: 3rem;
  width: 100%;
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 0.6rem;
}

.job-card-one {
  background-color: #bbdefb;
}

.job-card-two {
  background-color: #f0f4c3;
}

.job-card-three {
  background-color: #d1c4e9;
}

.job-card-four {
  background-color: #ffccbc;
}

.landing-section-two {
  height: 20rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  padding-bottom: 3.5rem;
  background-image: url("../../Assets/landing-background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  user-select: none;
}

.login-section {
  display: flex;
  text-align: center;
  justify-content: center;
}

.login-modal {
  width: 22rem;
  height: 12rem;
  background-color: #ffaf7f;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-around;
  align-items: center;
  padding: 2%;
}

.action-button-section {
  width: 100%;
}

.login-action-buttons {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-around;
}

.button-13 {
  background-color: #fff;
  border: 1px solid #d5d9d9;
  border-radius: 8px;
  box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
  box-sizing: border-box;
  color: #0f1111;
  cursor: pointer;
  display: inline-block;
  font-family: "Amazon Ember", sans-serif;
  font-size: 13px;
  line-height: 29px;
  padding: 0 10px 0 11px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: 100px;
}

.button-13:hover {
  background-color: #f7fafa;
}

.button-13:focus {
  border-color: #008296;
  box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
  outline: 0;
}

.landing-section-three {
  background-color: #d5ecff;
  height: 20rem;
  padding: 2rem;
  user-select: none;
}
.landing-section-four {
  background-color: #d3d3d3;
  height: 20rem;
  padding: 2rem;
  overflow-y: auto;
  user-select: none;
}

.blur-background {
  overflow: hidden;
  filter: blur(0.8rem);
}

.faq-card {
  background-color: #f8f9fa;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  background-color: #d5ecff;
  color: #000000;
  transition: background-color 0.3s ease;
}

.question-text {
  margin: 0;
}

.question:hover {
  background-color: #8bcbff;
}

.answer {
  display: none;
  padding: 15px;
  background-color: #fff;
}

.answer.show {
  display: block;
}

.arrow-button {
  border: none;
  background: none;
  font-size: 20px;
  color: black;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.arrow-button:hover {
  transform: rotate(180deg);
}

.landing-page .row {
  --bs-gutter-x: 0 !important;
} */

.landing-page {
  overflow: hidden;
}

.landing-page .row {
  padding: 0;
}

.advertisement-card {
  height: 3rem;
  width: 100%;
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 0.6rem;
}

.job-card-one {
  background-color: #bbdefb;
}

.job-card-two {
  background-color: #f0f4c3;
}

.job-card-three {
  background-color: #d1c4e9;
}

.job-card-four {
  background-color: #ffccbc;
}

.action-button-section {
  width: 100%;
}

.button-13 {
  background-color: #22b4ed;
  border: 1px solid #d5d9d9;
  border-radius: 8px;
  box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: "Amazon Ember", sans-serif;
  font-size: 13px;
  line-height: 29px;
  padding: 0 10px 0 11px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: 100px;
  height: 40px;
}

.button-13:hover {
  background-color: #777777;
}

.button-13:focus {
  border-color: #008296;
  box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
  outline: 0;
}

.blur-background {
  overflow: hidden;
  filter: blur(0.8rem);
  /* background: rgba(0, 0, 0, 0.5); */
}

.faq-card {
  background-color: #f8f9fa;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  background-color: #ececec;
  color: #000000;
  transition: background-color 0.3s ease;
}

.question-text {
  margin: 0; /* Remove default margin of <p> */
}

.question:hover {
  background-color: #bdbdbd;
}

.answer {
  display: none;
  padding: 15px;
  background-color: #fff;
}

.answer.show {
  display: block;
}

.arrow-button {
  border: none;
  background: none;
  font-size: 20px;
  color: black;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.arrow-button:hover {
  transform: rotate(180deg);
}

.landing-page .row {
  --bs-gutter-x: 0 !important;
}

.first-section {
  margin-top: 1rem;
  height: 15rem;
}

.first-section {
  padding: 1rem;
  border: transparent;
}
.third-section {
  height: 100%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* border: transparent; */
  padding: 2rem 1rem 0rem 1.5rem;
}

/* Add any additional styling you need for your sections */
.second-section {
  margin-top: 2rem;
  height: 19rem; /* Set the height of the section */
  overflow-y: auto; /* Add vertical scrollbar if content overflows */
  overflow-y: auto;
  user-select: none;
  padding: 1rem;
  border: transparent;
}

.faq-container {
  max-height: 100%; /* Limit the height of the container */
}

.second-section {
  scrollbar-color: rgba(255, 255, 255) rgb(255, 255, 255);
}

/* Add any additional styling you need for the FAQs */
.action-button-section {
  margin-top: 2rem; /* Adjust the margin value as needed */
}

.login-action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: center;
  padding-bottom: 1rem;
}

.login-action-buttons button {
  margin-right: 25px;
  font-size: 0.9rem;
}

.landing-page .row {
  --bs-gutter-x: 0 !important;
}

.second-section-title {
  margin-bottom: 2rem;
}

@media (max-width: 500px) {
  .second-section {
    margin-top: 6rem;
  }
  .second-section-title {
    margin-bottom: 2rem;
  }
}

.blur-background {
  overflow: hidden;
  filter: blur(0.8rem);
}

@media (max-width: 900px) {
  .third-section {
    padding: 2rem 1rem 0rem 1rem;
  }
}

.free-registration-popup {
  position: fixed;
  top: 4px;
  right: 20px;
  z-index: 1000;
  background-color: #000000;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 8px;
  animation: fadeInPop 3s infinite ease-in-out;
}

.free-registration-popup button {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
}

.free-registration-popup button:hover {
  text-decoration: underline;
}

@keyframes fadeInPop {
  0%,
  100% {
    opacity: 0;
    transform: scale(0.9) translateY(-1px);
  }
  50% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

.scrolling-text-container-parent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0.3rem;
}

.scrolling-text-container {
  position: relative;
  overflow: hidden;
  height: 50px;
  /* margin-top: 10px; */
  width: 100%;
}

.marquee {
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
}
.scrolling-text-container p {
  color: #22b4ed;
  font-weight: bold;
}

.marquee span {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 35s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(0%, 0);
  }
}
