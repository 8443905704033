.network-status-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6); /* Dark overlay */
  z-index: 9999;
}

.network-status-dialog {
  background-color: #fff;
  padding: 2rem 3rem;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.network-icon {
  font-size: 4rem;
  color: #ff6b6b;
  margin-bottom: 1rem;
}

.network-status-dialog h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
}

.network-status-dialog p {
  font-size: 1rem;
  color: #555;
}
