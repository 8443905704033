.password-input-container {
  position: relative;
}

.password-popover {
  position: absolute;
  top: calc(100% + 5px);
  right: 0rem;
  background-color: rgba(203, 204, 209);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 100;
  display: none;
  border-top-right-radius: 0;
}

@media (max-width: 768px) {
  .password-popover {
    position: absolute;
    top: calc(100% + 5px);
    right: 0;
    /* background-color: white; */
    background-color: rgba(203, 204, 209);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 100;
    display: none;
    border-top-right-radius: 0;
  }
}

.password-input-container.focused .password-popover {
  display: block;
}

.password-popover.show {
  display: block;
}

.form-control.invalid {
  border-color: red;
}

.form-control.valid {
  border-color: green;
}

.password-input-container {
  position: relative;
}

.eye-icon {
  position: absolute;
  right: 10px;
  top: 45%;
  transform: translateY(-50%);
  cursor: pointer;
}

.eye-icon svg {
  font-size: 1rem;
  color: #999;
}

.eye-icon:hover svg {
  color: #333;
}
