.no-candidates {
  color: #ff4e4e;
}

.card-cv-div {
  transition: box-shadow 0.3s ease;
  cursor: pointer;
}

.card-cv-div:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.cta-buttons-matched-candidates {
  border: none !important;
  font-size: 1.2rem !important;
}

.filter-section {
  margin-bottom: 1rem;
  text-align: right;
}

.filter-section svg {
  font-size: 1.8rem;
  cursor: pointer;
  color: #22b4ed;
  outline: none;
}

.dropdown-element {
  cursor: pointer;
}

.dropdown-element:hover {
  background-color: #dfdbdb;
  background-color: #22b4ed;
}

.dropdown-item.dropdown-element:hover {
  background-color: #22b4ed;
  color: #fff;
}

.filter-section {
  position: relative;
}

.filter-option {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.filter-label {
  margin-right: 1rem;
}

.name-profile-applicant {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  align-items: flex-end;
  margin-bottom: 0.7rem;
}

.name-profile-applicant svg {
  height: 2.5rem;
  width: 2.5rem;
  margin: 0 1.2rem 0rem 0;
  text-align: center;
  align-items: flex-end;
  border: 1px solid #22b4ed;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
}

.company-side-applicant-profile-image {
  height: 2.5rem;
  width: 2.5rem;
  margin: 0 1.2rem 0rem 0;
  text-align: center;
  align-items: flex-end;
  border: 2px solid #22b4ed;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
}

.company-side-applicant-profile-image:hover {
  transform: scale(1.5);
}
