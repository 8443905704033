.pdf-details-section {
  padding: 2%;
}

.visible-pdf-section {
  background-color: #ffffff44;
  border-radius: 0.4rem;
}

.detail-field {
  font-size: 1.2rem;
}

.details-container {
  border-width: 2px;
  border-color: #ffc06d;
}

.posted-jobs-download-button-div {
  display: flex;
}

.posted-jobs-download-button {
  cursor: pointer;
  margin-left: 2%;
}

.details-container {
  border: 2px solid #22b4ed !important;
  /* background-color: aliceblue !important; */
}
