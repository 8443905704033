/* TermsPage.css */

.terms-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  color: #333;
  margin: 0;
  padding: 0;
}

/* Ensure the content remains centered while navbar and footer are full width */
.terms-container {
  max-width: 1000px;
  width: 100%;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin: 40px auto;
}

/* Ensure navbar and footer stretch full width */
.NavbarLanding,
.Footer {
  width: 100%;
}

/* Styling for text content */
.terms-container h1 {
  text-align: center;
  font-size: 28px;
  color: #0b3d82;
  margin-bottom: 20px;
}

.terms-container h2 {
  font-size: 22px;
  color: #0b3d82;
  margin-top: 20px;
  border-bottom: 2px solid #0b3d82;
  padding-bottom: 5px;
}

.terms-container p {
  font-size: 16px;
  line-height: 1.6;
  margin: 10px 0;
  color: #555;
}

.terms-container ul {
  padding-left: 20px;
}

.terms-container li {
  margin-bottom: 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .terms-container {
    width: 90%;
    padding: 15px;
  }

  .terms-container h1 {
    font-size: 24px;
  }

  .terms-container h2 {
    font-size: 20px;
  }

  .terms-container p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .terms-container {
    padding: 10px;
  }

  .terms-container h1 {
    font-size: 22px;
  }

  .terms-container h2 {
    font-size: 18px;
  }

  .terms-container p {
    font-size: 13px;
  }
}
