/* ForgotPassword.css */
.forgot-password-page {
  overflow: hidden;
}

.password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}

.password-card {
  background-color: #fff;
  border: 3px solid #a7e6ff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 25rem;
  height: 15rem;
  background-color: aliceblue;
}

.password-card form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.password-form-control {
  width: 23rem;
  background-color: #8da3b8;
  color: white;
}

.password-form-label {
  color: #22b4ed; /* Dark blue label color */
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.password-form-control {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  border: none;
}

.btn-primary {
  background-color: #005f99;
  border: none;
  border-radius: 5px;
  padding: 10px;
  color: #fff;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #004080;
}

.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}

.progress-bar-inner {
  height: 100%;
  background-color: #00cc66;
  width: 0%;
  transition: width 0.3s;
}

.progress-50 .progress-bar-inner {
  width: 50%;
}

.progress-100 .progress-bar-inner {
  width: 100%;
}

.password-inner-card {
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .password-card {
    width: 22rem;
    height: 15rem;
  }
  .password-form-control {
    width: 20rem;
    background-color: #8da3b8;
    color: white;
  }
}
