.posted-jobs {
  overflow: hidden;
}

/* .jobs-card-section {
  height: 50rem;
} */

.job-card-item {
  padding: 5%;
  padding-top: 2%;
  display: flex;
  text-align: center;
  justify-content: center;
}

.jobs-title-section {
  display: flex;
  text-align: center;
  justify-content: space-between;
  padding: 2%;
  user-select: none;
  color: #22b4ed;
}

.matched-candidates-search-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.company-search-bar-div {
  padding: 0rem 1rem 0 1rem;
}

.company-search {
  display: inline-block;
  position: relative;
  width: -webkit-fill-available;
  font-size: 1.2rem;
  width: 18rem;
}

.company-search input[type="text"] {
  width: -webkit-fill-available;
  padding: 5px;
  border: 2px solid #a7e6ff;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #22b4ed;
  outline: none;
  padding-left: 0.5rem;
}

.company-search button[type="submit"] {
  background-color: aliceblue;
  border: 2px solid #a7e6ff;
  color: #22b4ed;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  right: 0;
  transition: 0.5s ease;
  outline: none;
}

.company-search button[type="submit"]:hover {
  color: rgb(255, 255, 255);
  background-color: #22b4ed;
  border: 2px solid #22b4ed;
}

.input-search-bar::selection {
  background-color: #22b4ed;
  color: #fff;
}

@media (max-width: 768px) {
  .company-search {
    width: 14rem;
  }
}
