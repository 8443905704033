.applicant-job-card-div {
  display: flex;
  flex-direction: column;
  height: 30%;
  justify-content: space-between;
  background-color: aliceblue;
  border: 2px solid #a7e6ff;
  margin-bottom: 6%;
  padding: 1rem 1.2rem;
  border-radius: 2rem;
  cursor: pointer;
}

.applicant-job-card-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.applicant-job-card-details-inner-div {
  display: flex;
  justify-content: space-between;
}

.applicant-job-card-title {
  color: black;
}

.applicant-job-card-title {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  align-items: flex-end;
  margin-bottom: 0.7rem;
}

.applicant-job-card-title svg {
  height: 2.5rem;
  width: 2.5rem;
  margin: 0 1.2rem 0rem 0;
  text-align: center;
  align-items: flex-end;
  border: 1px solid #22b4ed;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
}

.applicant-side-company-profile-image {
  height: 2.5rem;
  width: 2.5rem;
  margin: 0 1.2rem 0rem 0;
  text-align: center;
  align-items: flex-end;
  border: 2px solid #22b4ed;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
}

.applicant-side-company-profile-image:hover {
  transform: scale(1.5);
}
