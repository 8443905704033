.team-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  padding: 0px 30px 20px 40px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
}

.top-center-image {
  position: relative;
  width: 9rem;
  height: auto;
  margin-top: 20px;
}

.team-section h1 {
  font-size: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.member-info p {
  text-align: justify;
}
.team-members {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.team-member {
  display: flex;
  align-items: center;
  gap: 5px;
  max-width: 1000px;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.avatar {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 2rem;
  margin-bottom: 2rem;
}

.member-info {
  text-align: left;
  flex: 1;
}

.member-info h3 {
  margin: 0;
  font-size: 28px;
}

.member-info p {
  margin: 5px 0 0;
  font-size: 20px;
  color: #666;
  margin-bottom: 2rem;
}

@media (max-width: 1024px) {
  .team-section h1 {
    font-size: 40px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .top-center-image {
    width: 150px;
  }
  .avatar {
    width: 90px;
    height: 90px;
  }
  .member-info h3 {
    font-size: 24px;
  }
  .member-info p {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .team-section h1 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .team-members {
    gap: 15px;
  }
  .avatar {
    width: 80px;
    height: 80px;
  }
  .member-info h3 {
    font-size: 20px;
  }
  .member-info p {
    font-size: 16px;
  }
}
@media (max-width: 900px) {
  .team-section h1 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .team-members {
    gap: 15px;
  }
  .avatar {
    width: 8rem;
    height: 8rem;
  }
  .member-info h3 {
    font-size: 20px;
  }
  .member-info p {
    font-size: 16px;
  }
}
@media (max-width: 480px) {
  .team-section h1 {
    font-size: 24px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
  .top-center-image {
    width: 120px;
  }
  .team-member {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .avatar {
    width: 8rem;
    height: 8rem;
  }
  .member-info h3 {
    font-size: 18px;
  }
  .member-info p {
    font-size: 14px;
  }
}
