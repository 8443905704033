.applicant-job-card-div {
  display: flex;
  flex-direction: column;
  height: 30%;
  justify-content: space-between;
  background-color: aliceblue;
  border: 2px solid #a7e6ff;
  margin-bottom: 6%;
  padding: 1rem 1.2rem;
  border-radius: 2rem;
  cursor: pointer;
}

.applicant-job-card-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.applicant-job-card-details-inner-div {
  display: flex;
  justify-content: space-between;
}

.applicant-job-card-title {
  color: black;
}
