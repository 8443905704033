/* TradingTerms.css */

.trading-page {
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  color: #333;
  margin: 0;
  padding: 0;
}

.TradingTerms-page {
  max-width: 1000px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.TradingTerms-page h1 {
  font-size: 28px;
  color: #0b3d82;
  text-align: center;
  margin-bottom: 20px;
}

.TradingTerms-page h2 {
  font-size: 22px;
  color: #0b3d82;
  margin-top: 20px;
  border-bottom: 2px solid #0b3d82;
  padding-bottom: 5px;
}

.TradingTerms-page p {
  font-size: 16px;
  line-height: 1.6;
  margin: 10px 0;
}

.TradingTerms-page ul {
  padding-left: 20px;
}

.TradingTerms-page li {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .TradingTerms-page {
    width: 90%;
    padding: 15px;
  }

  .TradingTerms-page h1 {
    font-size: 24px;
  }

  .TradingTerms-page h2 {
    font-size: 20px;
  }

  .TradingTerms-page p {
    font-size: 14px;
  }
}
