/* General Page Styling */
.PrivacyPolicy-page {
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  color: #333;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Privacy Policy Content */
.privacy-policy-content {
  width: 90%;
  max-width: 1000px;
  background: #fff;
  padding: 20px;
  margin: 40px auto;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Main Title */
.privacy-policy-content h1 {
  font-size: 28px;
  color: #0b3d82;
  text-align: center;
  margin-bottom: 20px;
}

/* Section Titles */
.privacy-policy-content h2 {
  font-size: 22px;
  color: #0b3d82;
  margin-top: 20px;
  border-bottom: 2px solid #0b3d82;
  padding-bottom: 5px;
}

/* Subtitles */
.privacy-policy-content h3 {
  font-size: 18px;
  font-weight: bold;
  color: #555;
  margin-top: 15px;
}

/* Paragraph Text */
.privacy-policy-content p {
  font-size: 16px;
  line-height: 1.6;
  margin: 10px 0;
  color: #555;
  text-align: justify;
}

/* Lists */
.privacy-policy-content ul {
  padding-left: 20px;
}

.privacy-policy-content li {
  margin-bottom: 10px;
}

/* Navbar and Footer */
.NavbarLanding,
.Footer {
  width: 100%;
}

/* Responsive Design */
@media (max-width: 768px) {
  .privacy-policy-content {
    width: 90%;
    padding: 15px;
  }

  .privacy-policy-content h1 {
    font-size: 24px;
  }

  .privacy-policy-content h2 {
    font-size: 20px;
  }

  .privacy-policy-content h3 {
    font-size: 17px;
  }

  .privacy-policy-content p {
    font-size: 14px;
  }
}
