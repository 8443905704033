.feedback-page {
  overflow: hidden;
  background-color: white;
  font-family: "Inter", sans-serif;
}

.feedback-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.feedback-container h1 {
  font-size: 2.5rem;
  color: #22b4ed;

  margin-bottom: 1rem;
}

.feedback-container p {
  color: #555;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.feedback-contact-info-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  margin-bottom: 2rem;
  width: 100%;
}

.feedback-contact-list {
  list-style: none;
  padding: 0;
}

.feedback-contact-list li {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.feedback-contact-label {
  color: #333;
  font-weight: bold;
  margin-right: 0.5rem;
}

.feedback-contact-info {
  color: #555;
  padding: 0.5rem 0;
}

.feedback-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
}

.feedback-form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.feedback-form-group label {
  font-weight: bold;
  color: #22b4ed;
  margin-bottom: 0.5rem;
}

.feedback-input-boxes {
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 1rem;
  box-sizing: border-box;
}

.feedback-input-boxes:focus {
  border-color: #22b4ed;
  outline: none;
}

.feedback-btn {
  padding: 1rem;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.feedback-btn:hover {
  background-color: #4c944f;
}

.feedback-footer-social-icons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.feedback-footer-icons-container {
  padding: 0.5rem;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease;
}

.feedback-footer-icons-container:hover {
  transform: scale(1.1);
}

.feedback-icons-list {
  display: flex;
  gap: 1rem;
}

@media (max-width: 768px) {
  .feedback-contact-info-container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .feedback-container h1 {
    font-size: 2rem;
  }

  .feedback-container p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .feedback-container h1 {
    font-size: 1.8rem;
  }

  .feedback-container p {
    font-size: 0.9rem;
  }

  .feedback-btn {
    width: 100%;
  }

  .feedback-footer-social-icons {
    justify-content: center;
    margin-top: 1rem;
  }

  .feedback-footer-icons-container {
    margin: 0 0.5rem;
  }
}
