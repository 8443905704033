.company-page{
  overflow-x: hidden;
}

.container {
  padding: 2rem;
}

.card-1 {
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow */
  transition: box-shadow 0.3s ease; /* Add transition for hover effect */
  /* border: 2px solid #ff904b; */
  border-radius: 1rem;
  margin-bottom: 2rem;
  width: 100%;
  border: 2px solid #22b4ed;
}

.card-1:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
}

.card2 {
  border: 2px solid #22b4ed;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow */
  transition: box-shadow 0.3s ease; /* Add transition for hover effect */
}

.card2:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
}

.section-heading {
  text-align: center;
  margin-top: 2rem;
  color: #22b4ed;
}

.section-headingg {
  text-align: center;
  margin-top: 0.5rem;
  color: black;
  user-select: none;
}

.icon-container {
  display: flex;
  justify-content: space-between;
}

.icon {
  text-align: center;
}

.icon i {
  font-size: 2rem;
}

.icon p {
  margin-top: 10px;
}

.steps-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  margin-bottom: 2rem;
  user-select: none;
}

@media (max-width: 768px) {
  .steps-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5rem;
    /* margin-bottom: 2rem; */
    user-select: none;
  }
}

.title {
  margin-bottom: 2rem;
  color: #22b4ed;
}

.card2 .col-4 {
  display: flex;
  text-align: center;
  justify-content: center;
  color: #22b4ed;
}

.blur-background {
  overflow: hidden;
  filter: blur(0.8rem);
  /* background: rgba(0, 0, 0, 0.5); */
}

.step-one {
  width: 8rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-around;
}

.step-one svg {
  font-size: 4rem;
  color: #22b4ed;
}

.step-two {
  width: 8rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-around;
}

.step-two svg {
  font-size: 4rem;
  color: #22b4ed;
}

.step-three {
  width: 8rem;
  height: 4rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-around;
}

.step-three svg {
  font-size: 4rem;
  color: #22b4ed;
}

.steps-text {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

.matched-candidates-div {
  height: 36rem;
  overflow-y: auto;
  padding: 0.5rem 0.4rem 1rem 1rem;
}

.dropdown-element {
  cursor: pointer;
}

.dropdown-element:hover {
  background-color: #dfdbdb;
  background-color: #22b4ed;
}

.dropdown-item.dropdown-element:hover {
  background-color: #22b4ed;
  color: #fff;
}

.matched-job-title {
  /* color: #ed8722; good*/
  /* color: #22ed68; very good */
  color: #473683;
  /* color: #22eddc; */
}

/* .matched-candidates-search-bar{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.company-search-bar-div {
  padding: 0rem 1rem 0 1rem;
}

.company-search {
  display: inline-block;
  position: relative;
  width: -webkit-fill-available;
  font-size: 1.2rem;
  width: 18rem;
}

.company-search input[type="text"] {
  width: -webkit-fill-available;
  padding: 5px;
  border: 2px solid #a7e6ff;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #22b4ed;
  outline: none;
  padding-left: 0.5rem;
}

.company-search button[type="submit"] {
  background-color: aliceblue;
  border: 2px solid #a7e6ff;
  color: #22b4ed;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  right: 0;
  transition: 0.5s ease;
  outline: none;
}

.company-search button[type="submit"]:hover {
  color: rgb(255, 255, 255);
  background-color: #22b4ed;
  border: 2px solid #22b4ed;
}

.input-search-bar::selection {
  background-color: #22b4ed;
  color: #fff;
} */

.matched-candidates {
  /* background-color: yellow; */
  width: 90%;
  text-align: left;
}

.filter-section {
  position: relative;
}

.dropdown-menu {
  display: none;
  position: absolute;
  left: 0%;
  top: 100%;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.filter-section .dropdown-menu {
  display: block;
}

.filter-option {
  margin-bottom: 1rem;
}

.company-details-logo {
  display: flex;
  justify-content: space-around;
}
