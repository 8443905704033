/* .forum-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.discussion-section {
  width: 100%;
  padding: 10px;
  max-height: 600px;
  overflow-y: auto;
}

.message-container {
  position: relative;
  padding: 10px;
  margin-bottom: 45px;
  border-radius: 10px;
  background-color: #bdedff;
}

.message-container p {
  margin: 5px 0;
}

.message-time {
  text-align: right;
  font-size: 0.8em;
  color: gray;
}

.reply-button {
  position: absolute;
  bottom: -40px;
  right: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.post-section {
  width: 100%;
  border: 1px solid black;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  background-image: url("../../Assets/bg_chat.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.post-heading-container {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  padding: 10px;
}

.post-section h2 {
  color: white;
  align-self: center;
  margin-bottom: 20px;
}

.post-form-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
}

.post-section textarea {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid black;
  resize: vertical;
  min-height: 200px;
}

.post-button {
  align-self: flex-end;
  width: 100%;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.reply-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.reply-container textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid black;
  resize: vertical;
}

.post-reply-button {
  align-self: flex-end;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
}

.reply {
  margin-top: 10px;
  padding: 10px;
  border-left: 2px solid #007bff;
  background-color: white;
  border-radius: 5px;
}

.reply-show-more {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
}

.close-button {
  align-self: flex-end;
  background-color: transparent;
  color: black;
  border: none;
  font-size: 1em;
  cursor: pointer;
}

.close-button:hover {
  color: #555;
}

.reply-box {
  display: flex;
  justify-content: space-between;
}

.reply-text {
  flex: 1;
  text-align: left;
}

.reply-time {
  font-size: 0.8em;
  color: gray;
}

.message-actions {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
}

.edit-button,
.delete-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.edit-button:hover,
.delete-button:hover {
  color: #555;
}

.edit-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.edit-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.edit-modal-content h2 {
  margin-bottom: 15px;
  color: #333;
}

.edit-modal-content textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  resize: vertical;
  min-height: 100px;
  margin-bottom: 15px;
}

.save-edit-button,
.cancel-edit-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 14px;
  transition: background-color 0.3s;
}

.cancel-edit-button {
  background-color: gray;
}

.save-edit-button:hover,
.cancel-edit-button:hover {
  background-color: darken(#007bff, 10%);
}

.cancel-edit-button:hover {
  background-color: darken(gray, 10%);
}

.edit-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .forum-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .discussion-section {
    width: 65%;
  }

  .post-section {
    width: 32%;
    height: 500px;
  }

  .post-button {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .post-section,
  .discussion-section {
    width: 100%;
    margin-top: 10px;
  }

  .post-button {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
}

.message-title {
  margin-bottom: 1.2rem;
  user-select: none;
} */

.forum-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.discussion-section {
  width: 100%;
  padding: 10px;
  max-height: 600px;
  overflow-y: auto;
}
.message-header {
  display: flex;
  align-items: center;
}
.message-container {
  position: relative;
  padding: 10px;
  margin-bottom: 45px;
  border-radius: 10px;
  background-color: #bdedff;
}
.message-container p {
  margin: 5px 0;
}
.message-container h5 {
  margin: 5px 0;
  margin-left: 0.7rem;
}

.message-time {
  font-size: 0.8em;
  color: gray;
}
.parent-div {
  display: flex;
  justify-content: space-between;
}

.reply-button {
  color: #007bff;
  cursor: pointer;
  font-size: 20px;
}
.reply-button:hover,
.comment-count:hover {
  color: #555;
}
.comment-count {
  cursor: pointer;
  color: #007bff;
}
.post-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}

/* .post-message-section {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid black;
  background-image: url("../../Assets/bg_chat.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
} */

.post-heading-container {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  padding: 10px;
}

.post-message-section h2 {
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.post-form-container {
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
  justify-content: flex-end;
}

.post-message-section textarea {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid black;
  resize: vertical;
  min-height: 14rem;
}

.post-button {
  align-self: flex-end;
  width: 100%;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.reply-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.reply-container textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid black;
  resize: vertical;
}

.post-reply-button {
  align-self: flex-end;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
}

.reply {
  margin-top: 10px;
  padding: 10px;
  border-left: 2px solid #007bff;
  background-color: white;
  border-radius: 5px;
}

.reply-show-more {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
}

.close-button {
  align-self: flex-end;
  background-color: transparent;
  color: black;
  border: none;
  font-size: 1em;
  cursor: pointer;
}

.close-button:hover {
  color: #555;
}

.reply-box {
  display: flex;
  justify-content: space-between;
}

.reply-text {
  flex: 1;
  text-align: left;
}

.reply-time {
  font-size: 0.8em;
  color: gray;
}

.message-actions {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
}

.edit-button {
  color: #62acfc;
}
.delete-button {
  color: rgb(249, 106, 106);
}
.edit-button,
.delete-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
}

.edit-button:hover,
.delete-button:hover {
  color: #555;
}

.edit-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.edit-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.edit-modal-content h2 {
  margin-bottom: 15px;
  color: #333;
}

.edit-modal-content textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  resize: vertical;
  min-height: 100px;
  margin-bottom: 15px;
}

.save-edit-button,
.cancel-edit-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 14px;
  transition: background-color 0.3s;
}

.cancel-edit-button {
  background-color: gray;
}

.save-edit-button:hover,
.cancel-edit-button:hover {
  background-color: darken(#007bff, 10%);
}

.cancel-edit-button:hover {
  background-color: darken(gray, 10%);
}

.edit-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .forum-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .discussion-section {
    width: 65%;
  }

  .post-section {
    width: 32%;
    height: 37rem;
  }

  .post-button {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .post-section,
  .discussion-section {
    width: 100%;
    margin-top: 10px;
  }
  .post-section {
    height: 20rem;
  }

  .post-button {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
}

.forum-content h2 {
  text-align: center;
  color: black;
  margin-top: 10px;
}

.message-card {
  position: relative;
  padding: 10px;
  margin-bottom: 25px;
  border-radius: 10px;
  background-color: #f4f1f1;
}

.message-card p {
  margin: 5px 0;
}

.message-date {
  font-size: 0.8em;
  color: gray;
}
.messages-container {
  max-height: 250px; /* Adjust the height to show 2 messages at a time */
  overflow-y: auto;
  padding-right: 10px;
}
.fallback-profile-icon {
  font-size: 40px;
  color: black;
  border-radius: 50%;
  background: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
}
