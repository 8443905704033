.vision-container {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 40px 40px;
  background-color: #ffffff;
}

.vision-section {
  background-color: #69caf7;
  padding: 30px 15px;
  margin-bottom: 40px;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.vision-section:hover {
  cursor: pointer;
  background: #5cadd3;
}

.vision-section h1 {
  font-size: 40px;
  color: #000;
  margin-bottom: 20px;
  margin-top: 10px;
}

.vision-section p {
  font-size: 25px;
  color: #333;
  margin: 0;
}

.what-we-do-section {
  padding: 0px 10px;
}

.what-we-do-section h1 {
  margin-top: 4rem;
  font-size: 40px;
  margin-bottom: 3rem;
  color: #000;
}

.vision-cards-container {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 0 20px;
}

.vision-card {
  display: flex;
  flex-direction: row; /* Ensures image is to the right of the content */
  justify-content: space-between;
  background-color: #ebebeb;
  padding: 20px 0px 0px 20px;
  width: 48%;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
  transition: transform 0.2s, box-shadow 0.2s, background-color 0.3s;
  box-sizing: border-box;
  min-height: 100%;
  text-align: left;
}

.vision-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #e0f5ff;
  border-color: black;
}

.vision-card-content {
  flex: 1;
  margin-right: 15px;
}

.vision-card h1 {
  color: #000;
  margin-bottom: 10px;
}

.vision-card p {
  font-size: 14px; /* Slightly decrease the text size */
  color: #333;
  margin-bottom: 15px;
  text-align: justify;
}

.vision-card img {
  width: 15rem;
  height: auto;
  max-width: 100%;
  object-fit: contain;
  flex-shrink: 0; /* Prevents image from affecting the card size */
}

.vision-card a {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
}

@media (max-width: 768px) {
  .vision-cards-container {
    flex-direction: column;
    gap: 20px;
    padding: 0 10px;
  }
  .vision-card {
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 5%;
  }
  .vision-card-content {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .vision-section h1 {
    font-size: 30px;
  }
  .vision-section p {
    font-size: 16px;
  }
  .what-we-do-section h1 {
    font-size: 28px;
  }
  .vision-card h2 {
    font-size: 22px;
  }
  .vision-card p {
    font-size: 14px;
  }

  .vision-container {
    font-family: Arial, sans-serif;
    text-align: center;
    padding: 1rem;
    background-color: #ffffff;
  }
}
