.chatbot-container {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 50;
}

.open-chatbot-button {
  /* background: linear-gradient(to right, #6a11cb, #2575fc); */
  background: linear-gradient(to right, #0b3d82, #5d97e9);
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(147, 255, 101, 0.4);
  transition: background 0.3s ease;
}

.open-chatbot-button:hover {
  /* background: linear-gradient(to right, #2575fc, #6a11cb); */
  background: linear-gradient(to right, #5d97e9, #0b3d82);
}

.chatbot-modal {
  width: 450px; /* Increased width */
  height: 500px; /* Set desired height */
  background-color: #1e1e2f;
  color: white;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.4);
  padding: 16px; /* Reduced padding to maximize space */
  display: flex;
  flex-direction: column;
}

.chatbot-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #3a3a4f;
  padding-bottom: 8px;
  flex-direction: row;
}

.chatbot-header h2 {
  font-size: 18px;
  margin: 0;
}

.chatbot-close-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  color: white;
}

.chatbot-messages {
  flex-grow: 1; /* Ensure messages take up available space */
  max-height: calc(100% - 100px); /* Adjust based on header and input height */
  overflow-y: auto; /* Add scroll for long messages */
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.bot-message {
  border: 2px solid #3a3a4f; /* Border color */
  padding: 10px 14px;
  margin-bottom: 8px;
  border-radius: 16px 16px 16px 4px; /* Rounded edges except bottom-left */
  align-self: flex-start; /* Align chatbot messages to the left */
  max-width: 70%; /* Prevent overly wide messages */
  width: auto; /* Automatically adjust width to content */
  display: inline-block; /* Prevents full-width block layout */
  word-wrap: break-word; /* Ensures long words break into a new line */
  background-color: transparent; /* No background */
}

.user-message {
  border: 1px solid #3bf63e9b; /* Border color */
  text-align: right;
  align-self: flex-end; /* Align user messages to the right */
  border-radius: 16px 16px 4px 16px; /* Rounded edges except bottom-right */
  color: white;
  max-width: 70%; /* Prevent overly wide messages */
  width: auto; /* Automatically adjust width to content */
  display: inline-block; /* Prevents full-width block layout */
  word-wrap: break-word; /* Ensures long words break into a new line */
  background-color: transparent; /* No background */
}

.chatbot-input {
  display: flex;
  align-items: center; /* Vertically align input and send button */
  margin-top: auto; /* Push input to the bottom */
  padding: 8px 0; /* Adjust spacing */
  border-top: 1px solid #3a3a4f; /* Optional separator */
}

.input-box {
  flex-grow: 1; /* Input box takes remaining space */
  padding: 10px 14px;
  border: none;
  border-radius: 12px 0 0 12px;
  background-color: #2a2a3c;
  color: white;
  font-size: 16px;
  outline: none;
}

.send-button {
  padding: 10px 16px;
  background-color: #0057d1;
  color: white;
  border: none;
  border-radius: 0 12px 12px 0;
  cursor: pointer;
  transition: background 0.3s ease;
}

.send-button:hover {
  background-color: #0b3d82;
}
.chatbot-messages::-webkit-scrollbar {
  width: 8px;
}

.chatbot-messages::-webkit-scrollbar-thumb {
  background: #3a3a4f;
  border-radius: 8px;
}

.chatbot-messages::-webkit-scrollbar-track {
  background: #1e1e2f;
}

/* Mobile Phones (max-width: 480px) */
@media (max-width: 480px) {
  .chatbot-container {
    bottom: 16px;
    right: 16px; /* Keep button on the right side */
    left: unset; /* Remove left positioning */
  }

  .chatbot-modal {
    position: fixed;
    bottom: 60px; /* Align modal just above the button */
    right: 16px; /* Align the modal to the right */
    max-width: 100%; /* Make it 100% width for small screens */
    width: 300px; /* Or any width that fits well on small screens */
    padding: 12px;
  }

  .open-chatbot-button {
    padding: 10px 16px;
    font-size: 14px;
  }

  .chatbot-header h2 {
    font-size: 16px;
  }

  .message,
  .user-message {
    padding: 8px 12px;
    font-size: 14px;
  }

  .input-box {
    padding: 8px 12px;
    font-size: 14px;
  }

  .send-button {
    padding: 8px 12px;
    font-size: 14px;
  }
}

/* Tablets and Small Laptops (max-width: 768px) */
@media (max-width: 768px) {
  .chatbot-container {
    bottom: 16px;
    right: 16px; /* Keep button on the right side */
    left: unset; /* Remove left positioning */
  }

  .chatbot-modal {
    position: fixed;
    bottom: 60px; /* Align modal just above the button */
    right: 16px;
    max-width: 90%;
    padding: 14px;
  }

  .open-chatbot-button {
    padding: 12px 18px;
    font-size: 15px;
  }

  .chatbot-header h2 {
    font-size: 16px;
  }

  .message,
  .user-message {
    padding: 10px 14px;
    font-size: 15px;
  }

  .input-box {
    padding: 10px 14px;
    font-size: 15px;
    width: 100%;
  }

  .send-button {
    padding: 10px 14px;
    font-size: 15px;
  }
}

.job-suggestions {
  display: flex-wrap;
  flex-direction: row;
  padding: 2% 0;
}

.job-suggestion-button {
  border-radius: 1rem;
  font-size: 0.8rem;
  padding: 1% 2%;
  border: none;
  margin: 1%;
}

.loading-dots {
  font-size: 1rem;
  color: #fff;
  display: flex;
  justify-content: left;
  align-items: center;
  height: 30px;
}

.loading-dots::after {
  content: " ";
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-left: 5px;
  border-radius: 50%;
  background: #fff;
  animation: dots 1.5s infinite ease-in-out;
}

@keyframes dots {
  0% {
    box-shadow: 10px 0 0 -2px #fff, 20px 0 0 -2px #fff, 30px 0 0 -2px #fff;
  }
  50% {
    box-shadow: 10px -2px 0 0px #fff, 20px 0 0 -2px #fff, 30px 2px 0 0px #fff;
  }
  100% {
    box-shadow: 10px 0 0 -2px #fff, 20px 0 0 -2px #fff, 30px 0 0 -2px #fff;
  }
}
